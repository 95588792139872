<template>
    <div>
        <advert-preview ref="advertPreview" />
        <advert-upload
            style="margin-bottom: 20px; padding-left: 10px; padding-top: 10px; width: 300px"
            :file-type="advert.type"
            :isContinue="isContinue"
            v-model="advert.uploadFiles"
            :limit="1"
            @change="changeUploadFiles"
        />
        <div class="uploadInfo">
            <div class="info-line">1.文件上传限制大小为150M</div>
            <div class="info-line">2.支持拓展名：.rar,.zip,.doc,.docx,.xls,.xlsx</div>
        </div>
    </div>
</template>

<script>
import Util from 'js/Util';
import AdvertUtils, { AdvertFileUploadUrl } from 'js/AdvertUtils';
import AdvertPreview from './helperPreviewC';
import AdvertUpload from './helperUploadC.vue';
export default {
    name: 'NoviceGuidance',
    components: { AdvertPreview, AdvertUpload },
    data() {
        return {
            advert: {
                type: AdvertUtils.FileTypeEnum.FILE,
                uploadFiles: [],
            },
            form: {
                id: 1,
                flag: false,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            url: {
                page: '/system/backConfig/helpCenter/pageExtend',
                updatePage: '/system/backConfig/updateHelpCenter',
                infoList: '/system/backConfig/helpCenter/descDocInfo',
            },
            isContinue: false,
        };
    },
    created() {
        this.getUpdateList();
    },
    watch: {
        'advert.uploadFiles'(a, b) {
            if (a.length != 0) {
                this.isContinue = true;
            } else {
                this.isContinue = false;
            }
        },
    },
    methods: {
        getUpdateList() {
            this.$http({
                url: this.url.infoList,
                method: 'get',
            }).then((data) => {
                const param = {
                    name: data.data.data.fileName,
                    uid: 0,
                };
                this.advert.uploadFiles.push(param);
            });
        },
        changeUploadFiles(uploadFileArr, uploadFileUrlArr) {
            this.advert.uploadFiles = uploadFileArr;
            //拼接url地址，实际上由于只会上传一个文件，对应的只生成一个url地址，所以拼接无影响
            this.advert.uploadAccessory = uploadFileUrlArr
                .map((e = new AdvertFileUploadUrl()) => {
                    return e.advertFileUrl;
                })
                .join('');
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
    },
};
</script>
<style>
.uploadInfo {
    padding-left: 20px;
    padding-bottom: 20px;
}
.info-line {
    line-height: 30px;
}
</style>
