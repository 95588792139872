<template>
    <div class="Question">
        <div class="addLine">
            <el-button size="small" type="primary" @click="updateUrl('', 'newAdd')">新增</el-button>
        </div>
        <el-table border :data="tableData" style="width: 100%" max-height="500">
            <el-table-column prop="urlName" label="名称"> </el-table-column>
            <el-table-column prop="url" label="地址" width="500"> </el-table-column>
            <el-table-column prop="typeDesc" label="类型"></el-table-column>
            <el-table-column prop="valid" label="状态">
                <template slot-scope="scope">
                    <div v-if="scope.row.valid == true">启用</div>
                    <div v-else>停用</div>
                </template>
            </el-table-column>
            <el-table-column label="操作" width="120" header-align="center" prop="operate" key="operate">
                <template slot-scope="scope" style="text-align: center">
                    <el-button
                        size="small"
                        type="primary"
                        v-if="hasPrivilege('menu.system.HelpCenter.edit')"
                        @click="updateUrl(scope.row, 'update')"
                        >编辑</el-button
                    >
                </template>
            </el-table-column>
        </el-table>
        <el-pagination
            :page-sizes="page.pageSizes"
            :page-size="form.limit"
            :layout="page.PageStyle"
            :total="page.total"
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            @prev-click="handlePrevClick"
            @next-click="handleNextClick"
        />
        <el-dialog :title="titleDialog" :visible.sync="updateModel" width="30%" :before-close="closeDialog">
            <el-form ref="form" :model="updateForm" label-width="80px">
                <el-form-item label="名称" style="margin-bottom: 20px">
                    <el-input
                        @input="getNameRule(updateForm.urlName, 'name')"
                        placeholder="请输入菜单名称"
                        maxlength="100"
                        show-word-limit
                        v-model="updateForm.urlName"
                        style="width: 100%"
                    ></el-input>
                </el-form-item>
                <el-form-item label="地址" style="margin-bottom: 20px">
                    <el-input
                        @input="getNameRule(updateForm.url, 'url')"
                        placeholder="请输入跳转链接"
                        v-model="updateForm.url"
                        maxlength="255"
                        show-word-limit
                        style="width: 100%"
                    ></el-input>
                </el-form-item>
                <el-form-item label="类型" style="margin-bottom: 20px">
                    <el-radio-group v-model="updateForm.type">
                        <el-radio :label="'1'">常见问题</el-radio>
                        <el-radio :label="'2'">了解更多</el-radio>
                    </el-radio-group>
                </el-form-item>
                <el-form-item label="状态" style="margin-bottom: 20px">
                    <el-select v-model="updateForm.valid" placeholder="请选择状态">
                        <el-option label="开启" :value="true"></el-option>
                        <el-option label="停用" :value="false"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="updateModel = false">取 消</el-button>
                <el-button
                    type="primary"
                    v-if="titleDialog === '编辑' && hasPrivilege('menu.system.HelpCenter.edit')"
                    @click="handleClose"
                    >保 存</el-button
                >
                <el-button
                    type="primary"
                    v-if="titleDialog === '新增' && hasPrivilege('menu.system.HelpCenter.create')"
                    @click="handleCloseAdd"
                    >保存</el-button
                >
            </span>
        </el-dialog>
    </div>
</template>
<script>
import Util from 'js/Util';
import { Message } from 'element-ui';
import UrlUtils from 'js/UrlUtils';
export default {
    name: 'Question',
    data() {
        return {
            titleDialog: '新增',
            updateModel: false,
            updateForm: {
                type: '',
                urlName: '',
                url: '',
                valid: '',
            },
            updateId: '',
            form: {
                flag: true,
                page: 1,
                limit: Util.Limit,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            url: {
                page: '/system/backConfig/helpCenter/pageExtend',
                addPage: '/system/backConfig/helpCenter/create',
                updatePage: '/system/backConfig/helpCenter/patch_c/',
            },
            tableData: [],
        };
    },
    created() {
        this.handleQuery();
    },
    methods: {
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        updateUrl(item, type) {
            if (type === 'update') {
                this.updateId = item.code || true;
                this.updateForm.type = item.type.toString();
                this.updateForm.valid = item.valid || true;
                this.updateForm.urlName = item.urlName || true;
                this.updateForm.url = item.url || true;
                this.titleDialog = '编辑';
                this.updateModel = true;
            } else if (type === 'newAdd') {
                this.updateForm.type = '1';
                this.updateForm.valid = true;
                this.updateForm.urlName = '';
                this.updateForm.url = '';
                this.titleDialog = '新增';
                this.updateModel = true;
            }
        },
        closeDialog() {
            this.updateModel = false;
        },
        getNameRule(value, type) {
            if (value.length >= 256) {
                if (type === 'name') {
                    this.$message.error('业务错误，名称不能超过255字符');
                } else if (type === 'url') {
                    this.$message.error('业务错误，地址不能超过255字符');
                }
            }
        },
        isNull(str) {
            if (str === '') return true;
            var regu = '^[ ]+$';
            var re = new RegExp(regu);
            return re.test(str);
        },
        handleClose(done) {
            if (this.isNull(this.updateForm.urlName)) {
                this.$message.error('业务错误，名称不能为空');
                return;
            } else if (this.isNull(this.updateForm.url)) {
                this.$message.error('业务错误，地址不能为空');
                return;
            }
            this.updateModel = false;
            this.updateQuery();
        },
        handleCloseAdd(done) {
            if (this.isNull(this.updateForm.urlName)) {
                this.$message.error('业务错误，名称不能为空');
                return;
            } else if (this.isNull(this.updateForm.url)) {
                this.$message.error('业务错误，地址不能为空');
                return;
            } else if (this.isNull(this.updateForm.type)) {
                this.$message.error('业务错误，类型不能为空');
                return;
            } else if (this.isNull(this.updateForm.valid)) {
                this.$message.error('业务错误，状态不能为空');
                return;
            }
            this.updateModel = false;
            this.addQuery();
        },
        addQuery() {
            const _this = this;
            UrlUtils.PostRemote(this, _this.url.addPage, _this.updateForm, null, () => {
                _this.$message.success('保存成功');
                _this.handleQuery();
            });
        },
        updateQuery() {
            const _this = this;
            const _params = { params: _this.form };
            UrlUtils.PatchRemote(this, `${_this.url.updatePage}${this.updateId}`, _this.updateForm, null, (res) => {
                Message.success({ message: '修改成功' });
                Util.queryTable(_this, _this.url.page, _params);
            });
            // _this.$http.post(`${_this.url.updatePage}${this.updateForm.id}`, _this.updateForm).then((rst) => {
            //     if (rst.data.status == 200) {
            //         Message.success({ message: '修改成功' });
            //         Util.queryTable(_this, _this.url.page, _params);
            //         setTimeout(() => {
            //             // this.$router.push('/chooseGroup');
            //         }, 550);
            //     } else {
            //         Message.error({ message: '操作失败' + rst.data.status });
            //     }
            // });
        },
    },
};
</script>

<style scoped>
.Question {
    background: #fff;
}
.addLine {
    padding-left: 2px;
    margin-bottom: 10px;
}
</style>
