<template>
    <div class="DeptGroup">
        <el-card shadow="never" style="margin-top: 8px">后台配置</el-card>
        <div style="margin-top: 8px; background: #fff">
            <div class="lineTag">
                <div
                    @click="changeLineTag(1)"
                    class="lineTagItem"
                    :class="nowChooseLineTag == 1 ? 'actlineTagItem' : ''"
                >
                    常见问题
                </div>
                <div
                    @click="changeLineTag(2)"
                    class="lineTagItem"
                    :class="nowChooseLineTag == 2 ? 'actlineTagItem' : ''"
                >
                    新手引导
                </div>
            </div>
        </div>
        <el-card style="margin-top: 2px" shadow="never" v-if="nowChooseLineTag == 1">
            <QuestionPage></QuestionPage>
        </el-card>
        <el-card style="margin-top: 2px" shadow="never" v-if="nowChooseLineTag == 2">
            <NoviceGuidancePage></NoviceGuidancePage>
        </el-card>
        <el-dialog title="编辑" :visible.sync="updateModel" width="30%" :before-close="closeDialog">
            <el-form ref="form" :model="updateForm" label-width="80px">
                <el-form-item label="名称" style="margin-bottom: 20px">
                    <el-input
                        @input="getNameRule(updateForm.urlName, 'name')"
                        placeholder="请输入菜单名称"
                        v-model="updateForm.urlName"
                        style="width: 100%"
                    ></el-input>
                </el-form-item>
                <el-form-item label="地址" style="margin-bottom: 20px">
                    <el-input
                        @input="getNameRule(updateForm.url, 'url')"
                        placeholder="请输入跳转链接"
                        v-model="updateForm.url"
                        style="width: 100%"
                    ></el-input>
                </el-form-item>
                <el-form-item label="状态" style="margin-bottom: 20px">
                    <el-select v-model="updateForm.valid" placeholder="请选择状态">
                        <el-option label="开启" :value="true"></el-option>
                        <el-option label="停用" :value="false"></el-option>
                    </el-select>
                </el-form-item>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="updateModel = false">取 消</el-button>
                <el-button type="primary" @click="handleClose">保 存</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import Util from 'js/Util';
import QuestionPage from './helpPage/question.vue';
import NoviceGuidancePage from './helpPage/noviceGuidance.vue';
import { Message } from 'element-ui';

export default {
    name: 'HelpCenter',
    components: { QuestionPage, NoviceGuidancePage },
    data() {
        return {
            nowChooseLineTag: '1',
            updateModel: false,
            updateForm: {
                id: '',
                urlName: '',
                url: '',
                valid: '',
            },
            form: {
                id: 1,
            },
            page: {
                total: 0,
                pageSizes: Util.PageSizes,
                PageStyle: Util.PageStyle,
            },
            url: {
                page: '/system/backConfig/helpCenter',
                updatePage: '/system/backConfig/updateHelpCenter',
            },
            tableData: [],
        };
    },
    mounted() {
        // this.handleQuery();
    },
    methods: {
        changeLineTag(val) {
            this.nowChooseLineTag = val;
        },
        getNameRule(value, type) {
            if (value.length >= 256) {
                if (type === 'name') {
                    this.$message.error('业务错误，名称不能超过255字符');
                } else if (type === 'url') {
                    this.$message.error('业务错误，地址不能超过255字符');
                }
            }
        },
        isNull(str) {
            if (str === '') return true;
            var regu = '^[ ]+$';
            var re = new RegExp(regu);
            return re.test(str);
        },
        closeDialog() {
            this.updateModel = false;
        },
        handleClose(done) {
            this.$confirm('是否确认变更该信息？')
                .then((_) => {
                    if (this.isNull(this.updateForm.urlName)) {
                        this.$message.error('业务错误，名称不能为空');
                        return;
                    } else if (this.isNull(this.updateForm.url)) {
                        this.$message.error('业务错误，地址不能为空');
                        return;
                    }
                    this.updateModel = false;
                    this.updateQuery();
                    done();
                })
                .catch((_) => {});
        },
        updateUrl(item) {
            this.updateForm.id = item.id || true;
            this.updateForm.valid = item.valid || true;
            this.updateForm.urlName = item.urlName || true;
            this.updateForm.url = item.url || true;
            this.updateModel = true;
        },
        handleQuery() {
            const _this = this;
            const _params = { params: _this.form };
            Util.queryTable(_this, _this.url.page, _params);
        },
        updateQuery() {
            const _this = this;
            const _params = { params: _this.form };
            _this.$http.post(_this.url.updatePage, _this.updateForm).then((rst) => {
                if (rst.data.status == 200) {
                    Message.success({ message: '修改成功' });
                    Util.queryTable(_this, _this.url.page, _params);
                    setTimeout(() => {
                        this.$router.push('/chooseGroup');
                    }, 550);
                } else {
                    Message.error({ message: '操作失败' + rst.data.status });
                }
            });
        },
    },
};
</script>

<style scoped>
.DeptGroup .el-form-item {
    margin-bottom: 0;
}
.lineTag {
    height: 40px;
}
.lineTagItem {
    width: 150px;
    line-height: 40px;
    text-align: center;
    float: left;
    user-select: none;
    cursor: pointer;
}
.actlineTagItem {
    border-bottom: 2px solid #19c989;
    color: #19c989;
}
</style>
